import React from 'react';
import Contact from "./contact";

const MentionsLegales = () => {
  return (
    <>
      <div className={"strate last header_global"} id={"mlStrate1"}>
        <div id={"ml_wrapper"}>
          <div className="block">
            <h4>Ce site est édité par :</h4>

            <p>
              <b>TLC Marketing France</b><br/>
              92 avenue de Wagram – 75017 Paris<br/>
              N° SIREN : 491 414 306<br/>
              N° SIRET : 491 414 306 00036<br/>
              APE : 7311Z<br/>
              RCS : Paris B 491 414 306<br/>
              SARL au capital social de : 150 000€<br/>
              Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>FR 70 491 414 306<br/>
              Adresse e-mail : <br className={"d-lg-none"}/>
              <a href="mailto:bonjour@tlcworldwide.com?subject=Op%C3%A9ration%C2%A0%22Food%20Truck%22%20de%20La%20Fourn%C3%A9e%20Dor%C3%A9e">bonjour@tlcworldwide.com</a>
            </p>
          </div>
          <div className="block">
            <h4>Pour le compte de :</h4>
            <p>
              <b>La Fournée Dorée</b><br/>
              ZA Sud des Achards – 6 rue de l’Océan  – CS 60014 – <br className={"d-lg-none"}/>85150 Les Achards<br/>
              N° SIREN : 817 390 941<br/>
              N° SIRET : 817 390 941 00018<br/>
              APE : 1071A<br/>
              RCS : La Roche-Sur-Yon B 817 390 941<br/>
              SARL unipersonnelle au capital social de : <br className={"d-lg-none"}/>500 000€<br/>
              Numéro de TVA intracommunautaire : <br className={"d-lg-none"}/>FR 31 817 390 941
            </p>
          </div>
          <div className="block">
            <h4>Ce site est hébérgé par la société :</h4>
            <p>
              <b>Cloud Media</b><br/>
              6 rue du Général Clergerie – 75116 PARIS<br/>
              N° SIREN : 752 470 971<br/>
              N° SIRET : 752 470 971 00025<br/>
              APE : 6201Z<br/>
              RCS : Paris B 752 470 971<br/>
              SAS au capital social de : 8 000€<br/>
              Numéro de TVA intracommunautaire : FR 19 752 470 971
            </p>
          </div>

          <div className={"smallBlock"}>
            La conception, le design et le développement du site ont été effectués <br className={"d-none d-lg-block"}/>par les agences suivantes : <b>TLC Marketing France</b> et <b>Cloud Media</b>.
          </div>

        </div>
      </div>
    </>

  );
};

export default MentionsLegales;


