import React from 'react';
import {Link, withRouter} from 'react-router-dom';

/*import mixpanel from 'mixpanel-browser';
import {MixpanelProvider, MixpanelConsumer} from 'react-mixpanel';*/

import Routes from './routes';

import Header from "./header"
import Footer from "./footer"

import ScrollTop from "./_scrollTop.jsx"
import {Modal} from "react-bootstrap";


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.last_route = "";

        window.mixpanelhandler = {
            track : function(event, param){

                //console.log(window.mixpanel,process.env.REACT_APP_MIXPANEL_TOKEN)

                if(window.mixpanel !== undefined && process.env.REACT_APP_MIXPANEL_TOKEN != ""){
                    //mixpanel actif
                    //console.log("mixpanel dispo");

                    if(!this.init){
                        window.mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {api_host: process.env.REACT_APP_MIXPANEL_PROXY});
                        window.mixpanel.register({
                            "Country" : "France",
                            "Job ID" : process.env.REACT_APP_MIXPANEL_JOBID
                        });

                        this.init = true
                    }

                    window.mixpanel.track(event,param);
                }
                /*else if(process.env.REACT_APP_MIXPANEL_DEBUG && process.env.REACT_APP_MIXPANEL_DEBUG === "TRUE"){
                    //mixpanel inactif
                    console.log("mixpanel pas dispo");
                    console.log("track",event,param);
                }*/

                if(process.env.REACT_APP_MIXPANEL_DEBUG && process.env.REACT_APP_MIXPANEL_DEBUG === "TRUE"){
                    console.log("track ->",event,param);
                }

                return true;
            },
            init : false
        }
    }

    handleHideModal = () => {
        //TODO set view cookie
        localStorage.setItem("gameModal", 0)
        this.setState({showModal: '0'})
    }

    render() {

        let route = this.props.location.pathname.split("/")[1]
        route = route === "" ? "index" : route

        if (this.last_route !== "" && route !== this.last_route) {
            window.scrollTo(0, 0)
        }

        //envois des event page view
        switch(route){
            case "accueil" :                window.mixpanelhandler.track("Participation_Home loaded",{"Result count" : 1}); break;
            case "index" :                  window.mixpanelhandler.track("Participation_Quiz page viewed",{"Result count" : 1}); break;
            case "je-participe" :           window.mixpanelhandler.track("Participation_Form Viewed",{"Result count" : 1}); break;
            case "attente" :                window.mixpanelhandler.track("Participation_Coming Soon",{"Result count" : 1}); break;
            case "operation-terminee" :     window.mixpanelhandler.track("Participation_Ended",{"Result count" : 1}); break;
        }

        this.last_route = route;

        this.state = {
            showModal : localStorage.getItem("gameModal") ?? 1
        }

        return (
            <div className="page_wrapper" id={"page_" + route + "_wrapper"}>

                {route !== 'index' && route !== 'attente' && route !== 'operation-terminee' &&

                    <Modal id="start_modal" backdrop="static" show={this.state.showModal !== '0'} onHide={this.handleHideModal} centered>
                        <Modal.Body>
                            <a className={"cta"} href={"/quizz"} onClick={() => {
                                window.mixpanelhandler.track("\"Let's go\"",{"Result count" : 1});
                                this.handleHideModal()
                            }}>C'est parti !</a>
                            <Link className={"cta"} to={"/accueil"} onClick={() => {
                                window.mixpanelhandler.track("Discover offer_Selected",{"Result count" : 1})
                                this.handleHideModal()
                            }}>Découvrir l'offre</Link>
                        </Modal.Body>
                    </Modal>
                }

                <Header/>
                <div className="content_wrapper" id={"content_" + route + "_wrapper"}>
                    <Routes/>
                </div>
                <Footer/>
                <ScrollTop/>
            </div>
        );

    }
}

export default withRouter(Layout);
