import React, {useRef, useState} from 'react';

const Contact = () => {

  return (
    <>
      <div id="contactStrate1" className={"strate last"}>
        <div className={"anchor"} id={"contact"}/>
        <h2>Vous avez des questions<br/>ou besoin de plus d'informations ?</h2>
        <p>Le Service Consommateurs de TLC Marketing <br className={"d-lg-none"}/>reste à votre disposition :</p>

        <div id={"contact_item_wrapper"}>

          <div className={"contact_item"} id={"contact_item_phone"}>
            <div className={"content"}>
              <div className={"title"}>Par téléphone</div>
              <div className={"contact_link"}><a href={"tel:0974590344"}>09 74 59 03 44</a></div>
              <div className={"detail"}>
                (Appel non surtaxé, prix d’un appel local, tarif en vigueur selon opérateur.<br className={"d-lg-block d-none"}/> Ligne active du lundi au vendredi, de 9h30 à 17h30, hors <br className={"d-lg-none"}/>jours fériés)
              </div>
            </div>
          </div>

          <div className={"contact_item"} id={"contact_item_mail"}>
            <div className={"content"}>
              <div className={"title"}>Par e-mail</div>
              <div className={"contact_link"}>
                <a href={"mailto:lafourneedoree-foodtruck@tlcrewards.com?subject=Op%C3%A9ration%C2%A0%22Food%20Truck%22%20de%20La%20Fourn%C3%A9e%20Dor%C3%A9e%20%2F%20Vos%20nom%20et%20pr%C3%A9nom"}>lafourneedoree-foodtruck@tlcrewards.com</a>
              </div>
              <div className={"detail"}>
                (Objet : Opération "Food Truck" de La Fournée Dorée /<br className={"d-lg-none"}/> Vos nom et prénom)
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Contact;
