import React, {useEffect, useRef, useState} from 'react';
import {Modal} from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";

import {Link} from "react-router-dom";

const Quizz = (props) => {

    //modal state
    const [showStartModal, setShowStartModal] = useState(true);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [showEndModal, setShowEndModal] = useState(false);

    //all the usable questions
    const questions = [
        {
            id: 1,
            question: "Quel type de pain est couramment utilisé pour faire des hot dogs ?",
            answer:
                [
                    ["Brioché", true],
                    ["Façon baguette", false],
                    ["Façon Bretzel", false]
                ]
        },
        {
            id: 2,
            question: "Quel est le pays d'origine du bagel ?",
            answer:
                [
                    ["France", false],
                    ["Pologne", false],
                    ["Canada", true]
                ]
        },
        {
            id: 3,
            question: "En quelle année le premier food truck moderne est-il apparu aux États-Unis ?",
            answer:
                [
                    ["1872", true],
                    ["1899", false],
                    ["1927", false]
                ]
        },
        {
            id: 4,
            question: "Quelle ville est connue comme la \"capitale des food trucks\" du monde ?",
            answer:
                [
                    ["Tokyo", false],
                    ["New York", false],
                    ["Los Angeles", true]
                ]
        },
        {
            id: 5,
            question: "Quel est l'ingrédient principal d'un steak haché traditionnel pour burger? ",
            answer:
                [
                    ["Poulet", false],
                    ["Bœuf", true],
                    ["Porc", false]
                ]
        },
        {
            id: 6,
            question: "Quelle est l'une des caractéristiques clés d'un food truck ?",
            answer:
                [
                    ["Nécessite une réservation", false],
                    ["Mobilité", true],
                    ["Ne sert que des boissons", false]
                ]
        },
        {
            id: 7,
            question: "Quel aliment de rue populaire est souvent servi avec de la moutarde et de la choucroute ?",
            answer:
            [
                    ["Bretzel", false],
                    ["Hot dog", true],
                    ["Bagel", false]
                ]
        },
        {
            id: 8,
            question: "Quel processus de cuisson unique est utilisé pour faire des bagels ? ",
            answer:
                [
                    ["Vapeur", false],
                    ["Friture", false],
                    ["Ebulition", true]
                ]
        },
        {
            id: 9,
            question: "Quel est le record du monde de la consommation de hot dogs en 10 minutes ?",
            answer:
                [
                    ["55", false],
                    ["75", false],
                    ["85", true]
                ]
        },
        {
            id: 10,
            question: "Combien de temps en moyenne faut-il pour manger un burger ?",
            answer:
            [
                    ["3 minutes", false],
                    ["5 minutes", true],
                    ["8 minutes", false]
                ]
        },
        {
            id: 11,
            question: "Quelle est la ville française connue pour avoir le plus grand nombre de food trucks ?",
            answer:
                [
                    ["Paris", true],
                    ["Nice", false],
                    ["Lille", false]
                ]
        },
        {
            id: 12,
            question: "Quel est le burger le plus populaire dans le monde ?",
            answer:
            [
                    ["Hamburger", false],
                    ["Cheeseburger", true],
                    ["Bacon burger", false]
                ]
        },
        {
            id: 13,
            question: "Quel événement a inspiré la création du bagel, selon la légende ?",
            answer:
                [
                    ["La victoire des Allemands sur les Autrichiens", false],
                    ["La victoire des Français sur les Anglais", false],
                    ["La victoire des Polonais sur les Turcs", true]
                ]
        },
        {
            id: 14,
            question: "Quel est l'ingrédient principal d'un \"everything bagel\" ?",
            answer:
                [
                    ["Mélange de graines", true],
                    ["Fromage", false],
                    ["Cannelle", false]
                ]
        },
        {
            id: 15,
            question: "Quel est le diamètre moyen d'un bagel classique ?",
            answer:
                [
                    ["7cm-9cm", false],
                    ["10cm-12cm", true],
                    ["13cm-15cm", false]
                ]
        },
        {
            id: 16,
            question: "Quel est le pays d'origine du bretzel ?",
            answer:
                [
                    ["Autriche", false],
                    ["Allemagne", true],
                    ["Suisse", false]
                ]
        },
        {
            id: 17,
            question: "Quelle fête allemande est célèbre pour ses bretzels géants ?",
            answer:
                [
                    ["Carnaval", false],
                    ["Noël", false],
                    ["Oktoberfest", true]
                ]
        },
        {
            id: 18,
            question: "Combien de temps un bretzel traditionnel est-il généralement bouilli avant d'être cuit ?",
            answer:
                [
                    ["30-60 secondes", true],
                    ["1-2 minutes", false],
                    ["5 minutes", false]
                ]
        },
        {
            id: 19,
            question: "En quelle année le premier stand de hot dogs a-t-il été ouvert à Coney Island ?",
            answer:
                [
                    ["1871", true],
                    ["1890", false],
                    ["1905", false]
                ]
        },
        {
            id: 20,
            question: "Quel type de fromage est le plus couramment utilisé dans un cheeseburger ?",
            answer:
                [
                    ["Cheddar", true],
                    ["Gouda", false],
                    ["Roquefort", false]
                ]
        },
        {
            id: 21,
            question: "Quel est le nom du célèbre festival de food trucks qui a lieu à Paris chaque année ?",
            answer:
                [
                    ["Street Food Paris", false],
                    ["Le Food Market", false],
                    ["Le Food Trucks Festival", true]
                ]
        },
        {
            id: 22,
            question: "Quelle est la dernière nouveauté dans la gamme des pains préemballés chez La Fournée Dorée ?",
            answer:
                [
                    ["Les Viennois Tranchés", false],
                    ["Les Croissants Burgers", true],
                    ["Les Bagels au Paprika", false]
                ]
        },
        {
            id: 23,
            question: "Quel est l'ingrédient principal de toute la gamme des pains préemballés chez La Fournée Dorée ? ",
            answer:
                [
                    ["De la farine de blé française", true],
                    ["Du levain", false],
                    ["Du lait", false]
                ]
        },
        {
            id: 24,
            question: "Quelle est l'épice utilisée dans la recette de l'un de nos Brioch'Burger ? ",
            answer:
                [
                    ["Gingembre", false],
                    ["Curry", false],
                    ["Paprika", true]
                ]
        },
        {
            id: 25,
            question: "D'où vient la tradition de fendre les pains à hot dogs par le dessus ? ",
            answer:
                [
                    ["Angleterre", false],
                    ["Etats-Unis", false],
                    ["Mexique", true]
                ]
        },
        {
            id: 26,
            question: "Combien de variétés de céréales composent les Brioch'Burgers aux céréales ?",
            answer:
                [
                    ["5", false],
                    ["7", true],
                    ["9", false]
                ]
        },
        {
            id: 27,
            question: "Quel est le nombre de calories contenues dans le burger le plus gras au monde, servi à Las Vegas ? ",
            answer:
                [
                    ["500", true],
                    ["2000", false],
                    ["9000", false]
                ]
        },
        {
            id: 28,
            question: "Combien coûte le burger le plus cher au monde, créé par le restaurant londonien Honky Tonk ?",
            answer:
                [
                    ["100 €", true],
                    ["700 €", false],
                    ["1 400 €", false]
                ]
        },
        {
            id: 29,
            question: "Quelle est la ville connue pour avoir popularisé le bagel avec une pâte plus sucrée et plus élastique ?",
            answer:
                [
                    ["New York", false],
                    ["Montréal", true],
                    ["Chicago", false]
                ]
        },
        {
            id: 30,
            question: "Pourquoi le hot dog s'appelle-t-il ainsi ? ",
            answer:
                [
                    ["Parce que la saucisse ressemblait à un teckel", false],
                    ["Parce que les Américains doutaient de la nature de la viande", false],
                    ["Les deux réponses précédentes", true]
                ]
        },
        {
            id: 31,
            question: "Combien de hot dogs les américains mangent-ils chaque année ? ",
            answer:
                [
                    ["20 millions", false],
                    ["15 milliards", true],
                    ["200 milliards", false]
                ]
        },
        {
            id: 32,
            question: "Pourquoi les bagels ont-ils un trou au milieu ?",
            answer:
                [
                    ["Faciliter la cuisson", true],
                    ["Esthétisme", false],
                    ["Transport", false]
                ]
        },
        {
            id: 33,
            question: "Comment appelle-t-on la saucisse à mettre dans les hot dogs ? ",
            answer:
                [
                    ["Saucisse de Morteau", false],
                    ["Saucisse de Francfort", true],
                    ["Saucisse de Toulouse", false]
                ]
        }
    ];

    const [quizzQuestions, setQuizzQuestions] = useState([])    //questions
    const [selectedAnswers, setSelectedAnswers] = useState(null)        //current selected response id (0,1,2)
    const [questionCount, setQuestionCount] = useState(1)       //current question number
    const [answerIsGood,  setAnswerIsGood] = useState(false)    //current answer status
    const [goodAnswer,  setGoodAnswer] = useState(0)    //good answer count

    const questionsTemp = useRef()

    //timer
    const [timerValue, setTimerValue] = useState(45)
    const timerRef = useRef(null)


    const selectQuestions = () => {

        let questionArr = [];

        for(let i=0; i<5; i++){
            const random = Math.floor(Math.random() * questionsTemp.current.length)
            questionArr.push(questionsTemp.current[random])
            questionsTemp.current.splice(random, 1)
        }

        setQuizzQuestions(questionArr)
    }
    const selectAnswer = (correct,id) => {

        //add class selected to answer
        setSelectedAnswers(id)

        if(correct)
            setGoodAnswer(goodAnswer+1)

        //show modal depending on good or bad answer
        setAnswerIsGood(correct)
        setShowResponseModal(true)
        pauseTimer()
    }
    const nextQuestion = () => {

        //TODO handle end of the game
        //TODO stop and start timer on shhow and hide modal

        setShowResponseModal(false)

        if(questionCount >=5){
            stopGame()
        }
        else{
            setTimeout(() => {
                setSelectedAnswers(null)
                setQuestionCount((questionCount) => questionCount+1)
            },200)

            startTimer()
        }
    }
    const startGame = () => {

        //hide modal on the rest of the website
        localStorage.setItem("gameModal", 0)

        //hide modal
        setShowStartModal(false)
        setShowEndModal(false)

        //reset question array
        questionsTemp.current = questions

        //reset everything
        setQuestionCount(1)
        setTimerValue(45)
        setGoodAnswer(0)
        setSelectedAnswers(null)

        //select 5 question randomly
        selectQuestions()

        //start timer
        startTimer()
    }

    const startTimer = () => {
        //démmare le timer
        timerRef.current = setInterval( () => setTimerValue(timerValue => timerValue - 1), 1000)
    }

    const stopGame = () => {
        pauseTimer()
        window.mixpanelhandler.track("Game_Completed",{"Result count" : 1});
        setShowEndModal(true);
    }
    const pauseTimer = () => {
        clearInterval(timerRef.current);
    }

    useEffect(() => {
        if(timerValue === 0)
            stopGame()

    },[timerValue]);


    return (
        <>
            <Modal id="start_modal" backdrop="static" show={showStartModal} centered>
                <Modal.Body>
                    <a className={"cta"} href={"#0"} onClick={() => {
                        window.mixpanelhandler.track("\"Let's go\"",{"Result count" : 1});
                        startGame()
                    }}>C'est parti !</a>
                    <Link className={"cta"} to={"/accueil"} onClick={() => localStorage.setItem("gameModal", 0)}>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>
            <Modal id="reponse_modal" backdrop="static" show={showResponseModal} centered>
                <Modal.Body className={`${answerIsGood ? 'good' : 'bad'}`}>
                    {answerIsGood && <div>Bonne réponse</div>}
                    {!answerIsGood &&
                        <>
                            <div>Mauvaise réponse</div>
                            <p>La bonne réponse est</p>
                            <div>“{quizzQuestions[questionCount-1]?.answer.filter((answer) => answer[1])}”</div>
                        </>
                    }

                    <a href={"#0"} className={"cta"} onClick={nextQuestion}>
                        {questionCount !== 5 && "Question suivante"}
                        {questionCount === 5 && "Résultat"}
                    </a>
                </Modal.Body>
            </Modal>
            <Modal id="end_modal" backdrop="static" show={showEndModal} centered>
                <Modal.Body className={goodAnswer === 0 ? "bad" : "good"}>
                    <div className={"content"}>
                        <div>
                            {goodAnswer === 0 && "Dommage !"}
                            {goodAnswer === 1 && "Dommage !"}
                            {goodAnswer === 2 && "Pas mal !"}
                            {goodAnswer === 3 && "Bravo !"}
                            {goodAnswer === 4 && "Félicitation !"}
                            {goodAnswer === 5 && "Félicitation !"}
                        </div>
                        <p>
                            Vous avez eu&nbsp;
                            <span>
                                {goodAnswer}
                                {goodAnswer > 1 && " bonnes réponses, "}
                                {goodAnswer <= 1 && " bonne réponse, "}
                            </span>
                            <br/>
                            {goodAnswer === 0 && "retentez vite votre chance."}
                            {goodAnswer === 1 && "retentez vite votre chance."}
                            {goodAnswer === 2 && "retentez vite votre chance."}
                            {goodAnswer === 3 && "la prochaine sera la bonne."}
                            {goodAnswer === 4 && "la prochaine sera la bonne."}
                            {goodAnswer === 5 && "vous êtes incollable sur la street food."}
                        </p>
                    </div>
                    <a className={"cta"} href={"#0"} onClick={() => {
                        startGame()
                        window.mixpanelhandler.track("Game Replay_Selected",{"Result count" : 1});
                    }}>Rejouer</a>
                    <Link className={"cta"} to={"/accueil"} onClick={() => window.mixpanelhandler.track("Discover offer_Selected",{"Result count" : 1})}>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>

            <div id={"quizz_wrapper"} className={"strate header_global last"}>
                <div className={"question_count"}>
                Question <span className={"count"}>{questionCount}</span><span className={"total"}>/5</span>
                </div>
                <div id={"timer"}>{timerValue}s</div>
                {quizzQuestions[questionCount - 1] !== undefined &&
                    <div className={"question_wrapper"} id={`question_wrapper${quizzQuestions[questionCount - 1].id}`}>
                        <div className={"picture"}/>
                        <div className={"title"} dangerouslySetInnerHTML={{__html: quizzQuestions[questionCount - 1].question}}/>
                        <div className={"answer_wrapper"}>
                            {
                                quizzQuestions[questionCount - 1].answer.map((answer, i) => {
                                    return (<div key={`answer${i}`} id={`answer_${i}`} className={selectedAnswers === i ? "selected" : ""} dangerouslySetInnerHTML={{__html: answer[0]}} onClick={() => selectAnswer(answer[1], i)}/>);
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )

}

const QuizzOLD = () => {


    const [showStartModal, setShowStartModal] = useState(true);
    const [showReponseModal, setShowReponseModal] = useState(false);
    const [showEndModal, setShowEndModal] = useState(false);
    const [answer, setAnswer] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [questionCount, setQuestionCount] = useState(1);
    const [goodAnswer, setGoodAnswer] = useState(0);

    const [timerValue, setTimerValue] = useState(45);

    const [isCtaVisible, setIsCtaVisible] = useState(false);


    const timerRef = useRef();
    const timerScrollRef = useRef();
    const questionsTemp = useRef();

    //question / slug réponse / réponse / anecdote
    const questions = [
        ["Dans quelle région se situent les Gorges du Verdon, plus grand Canyon d'europe ?","paca","Provence-Alpes-Côte d'Azur"],
        ["Dans quelle région se fait-on le plus de bises pour se dire bonjour ?","corse","Corse"],
        ["Dans quelle région mange-t-on le plus de brioche ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région fabrique-t-on le Chinois de La Fournée Dorée ?","grand_est","Grand-Est"],
        ["Dans quelle région est née la brioche ?","normandie","Normandie "],
        ["Dans quelle région pratique-t-on la danse de la brioche lors des mariages ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région se situe le siège de La Fournée Dorée ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région a été fabriquée la plus grande brioche de France, mesurant 117,55 mètres ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région fabrique-t-on la Gâche de La Fournée Dorée ?","pays_de_la_loire","Pays de la Loire"],
        ["Quelle est la région la plus peuplée ?","ile_de_france","Île-de-France"],
        ["Quelle région est surnommée \"le jardin de la France\" ?","centre_val_de_loire","Centre-Val de Loire"],
        ["Quelle région est la plus grande en superficie ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Quelle région est la seule à avoir une frontière commune avec 4 pays différents ?","grand_est","Grand-Est"],
        ["Quelle région est la plus visitée par les touristes étrangers ?","paca","Provence-Alpes-Côte d'Azur"],
        ["Quelle région possède le plus grand nombre de sites classés au patrimoine mondial de l'UNESCO ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Quelle région est connue pour sagastronomie à base de fromages et de charcuterie ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Quelle région est la plus ancienne ?","bretagne","Bretagne"],
        ["Quelle région est la plus petite en superficie ?","corse","Corse"],
        ["Quelle région est la plus montagneuse ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Quelle région est la seule à avoir une langue régionale officielle ?","corse","Corse"],
        ["Quelle est la région la moins peuplée ?","corse","Corse"],
        ["Quelle région est considérée comme la plus culturelle ?","ile_de_france","Île-de-France"],
        ["Quelle région est considérée comme la plus festive ?","bretagne","Bretagne"],
        ["Quelle région possède le plus de côtes maritimes ?","bretagne","Bretagne"],
        ["Dans quelle région a été portée la fameuse chemise à rayures pour la première fois ?","bretagne","Bretagne"],
        ["Quelle région est célèbre pour ses châteaux médiévaux ?","centre_val_de_loire","Centre-Val de Loire"],
        ["Dans quelle région se situe la ville dite \"rose\" ?","occitanie","Occitanie"],
        ["Dans quelle région sont nés les bateaux-mouches ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Dans quelle régon se situe la plus longue rue piétonne d'Europe ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Dans quelle région a lieu la plus grande braderie de France ?","haut_de_france","Hauts-de-France"],
        ["De quelle région est originaire le Bretzel ?","grand_est","Grand-Est"],
        ["De quelle région partent les coureurs du Vendée Globe ?","pays_de_la_loire","Pays de la Loire"],
        ["Dans quelle région arrivent les coureurs du Tour de France ?","ile_de_france","Île-de-France"],
        ["Dans quelle région utilise-t-on le plus souvent le mot \"Chocolatine\" ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Dans quelle région le 26 décembre est également un jour férié ?","grand_est","Grand-Est"],
        ["Dans quelle région se trouve la commune qui possède le nom le plus court ?","haut_de_france","Hauts-de-France"],
        ["Dans quelle région se trouve le plus vieil arbre de France (un olivier) ?","paca","Provence-Alpes-Côte d'Azur"],
        ["Dans quelle région trouve-t-on le plus grand nombre de vaches par rapport au nombre d'habitants ?","normandie","Normandie "],
        ["Dans quelle région la raclette est-elle considérée comme une spécialité culinaire ?","auvergne_rhone_alpes","Auvergne-Rhône-Alpes"],
        ["Dans quelle région le bœuf bourguignon est-il considéré comme une spécialité culinaire ?","bourgogne_franche_comte","Bourgogne-Franche-Comté"],
        ["Dans quelle région les crêpes sont-elles considérées comme une spécialité culinaire ?","bretagne","Bretagne"],
        ["Dans quelle région la tarte tatin est-elle considérée comme une spécialité culinaire ?","centre_val_de_loire","Centre-Val de Loire"],
        ["Dans quelle région la charcuterie est-elle considérée comme une spécialité culinaire ?","corse","Corse"],
        ["Dans quelle région la choucroute est-elle considérée comme une spécialité culinaire ?","grand_est","Grand-Est"],
        ["Dans quelle région les moules-frites sont-elles considérées comme une spécialité culinaire ?","haut_de_france","Hauts-de-France"],
        ["Dans quelle région le macaron est-il considéré comme une spécialité culinaire ?","ile_de_france","Île-de-France"],
        ["Dans quelle région le cannelé est-il considéré comme une spécialité culinaire ?","nouvelle_aquitaine","Nouvelle-Aquitaine"],
        ["Dans quelle région le cassoulet est-il considéré comme une spécialité culinaire ?","occitanie","Occitanie"],
        ["Dans quelle région la bouillabaisse est-elle considérée comme une spécialité culinaire ?","paca","Provence-Alpes-Côte d'Azur"]
    ];


    const selectQuestion = () => {
        const random = Math.floor(Math.random() * questionsTemp.current.length)
        setCurrentQuestion(questionsTemp.current[random])
        questionsTemp.current.splice(random, 1)
    }
    const startGame = () => {

        //start or reset the game
        setShowStartModal(false);
        setShowEndModal(false);

        //reset question array
        questionsTemp.current = questions;

        //reset everything
        setCurrentQuestion("");
        setAnswer("");
        setQuestionCount(1);
        setGoodAnswer(0);
        setTimerValue(45);

        //set first question
        selectQuestion()
        startTimer()
    }
    const nextQuestion = () => {

        setShowReponseModal(false)

        //finis
        if(questionCount >=5){
            setShowEndModal(true);
        }
        else{
            selectQuestion()
            setQuestionCount((questionCount) => questionCount+1)
            setAnswer("")
            startTimer()
        }
    }
    const submit = () => {

        if(answer === "")
            return;

        window.scrollTo(0,0)

        //pause timer
        pauseTimer()
        setShowReponseModal(true)

        if(answer === currentQuestion[1]){
            setGoodAnswer((goodAnswer) => goodAnswer+1)
        }
    }

    const startTimer = () => {
        //démmare le timer
        timerRef.current = setInterval( () => {
            setTimerValue(timerValue => timerValue - 1)
        }, 1000)
    }
    const pauseTimer = () => {
        clearInterval(timerRef.current);
    }

    useEffect(() => {
        return () => clearInterval(timerRef.current)
    },[])

    useEffect(() => {
        if(timerValue === 0){
            pauseTimer()
            setShowEndModal(true);
        }
    },[timerValue]);

    return (
        <>
            <Modal id="start_modal" backdrop="static" show={showStartModal} centered>
                <Modal.Body>
                    <a className={"cta"} href={"#0"} onClick={startGame}>C'est parti !</a>
                    <Link className={"cta"} to={"/accueil"}>Découvrir l'offre</Link>
                </Modal.Body>
            </Modal>
            <Modal id="reponse_modal" backdrop="static" show={showReponseModal} centered>
                <Modal.Body className={`${answer === currentQuestion[1] ? 'good' : 'bad'}`}>
                    {answer === currentQuestion[1] && <div>Bonne réponse</div>}
                    {answer !== currentQuestion[1] &&
                        <>
                            <div>Mauvaise réponse</div>
                            <p>La bonne réponse est</p>
                            <div>“{currentQuestion[2]}”</div>
                        </>
                    }

                    <a href={"#0"} onClick={nextQuestion}>Valider</a>
                </Modal.Body>
            </Modal>
            <Modal id="end_modal" backdrop="static" show={showEndModal} centered>
                <Modal.Body>
                    <div className={"content"}>
                        <div className={goodAnswer === 0 ? "bad":"good"}>
                            {goodAnswer === 0 && "Dommage !"}
                            {goodAnswer === 1 && "Dommage !"}
                            {goodAnswer === 2 && "Pas mal !"}
                            {goodAnswer === 3 && "Bravo !"}
                            {goodAnswer === 4 && "Félicitation !"}
                            {goodAnswer === 5 && "Félicitation !"}
                        </div>
                        <p>
                            Vous avez eu&nbsp;
                            <span>
                                {goodAnswer}
                                {goodAnswer > 1 && " bonnes réponses, "}
                                {goodAnswer <= 1 && " bonne réponse, "}
                            </span>
                            <br/>
                            {goodAnswer === 0 && "retentez vite votre chance."}
                            {goodAnswer === 1 && "retentez vite votre chance."}
                            {goodAnswer === 2 && "retentez vite votre chance."}
                            {goodAnswer === 3 && "la prochaine sera la bonne."}
                            {goodAnswer === 4 && "la prochaine sera la bonne."}
                            {goodAnswer === 5 && "vous connaissez les régions de France par cœur."}
                        </p>
                    </div>
                    <a href={"#0"} onClick={ () => {
                        window.mixpanelhandler.track("Participation_Quiz Replay Submitted",{"Result count" : 1});
                        startGame();
                    }}></a>
                    <Link to={"/accueil"}></Link>
                </Modal.Body>
            </Modal>

            <div id={"quizz"} className={"strate header_global1 last"}>

                <div className={"game_wrapper"}>
                    <div className={"game_part"}>
                    <div id={"timer"}>{timerValue} secondes</div>
                    <div id={"question"}>
                        <div>Question {questionCount}/5</div>
                        <div>{currentQuestion[0]}</div>
                    </div>
                    <a className={"cta d-none d-lg-block"} href={"#0"} onClick={submit}>Valider</a>
                </div>
                </div>
            </div>
        </>
    );
}

export default Quizz;
