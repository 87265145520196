import React from 'react';
//import MixpanelContext from "react-mixpanel/lib/MixpanelContext";

import Contact from "./contact";
import {Link} from "react-router-dom";
const FinOpe = () => {

	//const mixpanel = React.useContext(MixpanelContext);

	return(
		<>
			<div id="strate1" className={"strate header_global"}>
				<Link to={"/"}/>
				<p>
					Vous pouvez également retrouver toute <br className={"d-lg-none"}/>notre actualité sur<br/>
					<a href={"https://www.lafourneedoree.fr"} target={"_blank"}>www.lafourneedoree.fr</a>
				</p>
			</div>

			<Contact/>
		</>
	);
}
export default FinOpe;
