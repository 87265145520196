import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Modal} from "react-bootstrap";
import Contact from "./contact";

//import MixpanelContext from "react-mixpanel/lib/MixpanelContext";
import VisibilitySensor from "react-visibility-sensor";

const Home = () => {

    //const mixpanel = React.useContext(MixpanelContext);

    const [isCarouselHowToVisible,setIsCarouselHowToVisible] = useState(false)
    const [isCarouselDotationToVisible,setIsCarouselDotationToVisible] = useState(false)

    const createCarouselHowTo = () => {

        const content = [
            [
                "Achetez",
                "simultanément ou non, deux produits La Fournée Dorée",
                [
                    "porteurs de l’offre entre le <b>19 août et le 20 octobre 2024 inclus.</b>",
                    "<b>Un code unique</b> figure au dos de chaque sticker collé sur tous les produits porteurs de l’offre. <b>Conservez bien ces codes uniques</b>, vous en aurez besoin pour enregistrer votre participation <a href='/je-participe' target='_blank' class='underline'>ici</a> <b>jusqu’au 3 novembre 2024 à 23h59</b> (heure de connexion en France Métropolitaine faisant foi)."
                ]
            ],
            [
                "COMPLÉTEZ",
                "LE FORMULAIRE DE PARTICIPATION",
                [
                    "en renseignant notamment vos deux <b>codes uniques</b> ainsi que<br class='d-none d-lg-block'/> vos <b>coordonnées complètes</b>. Précisez la catégorie ainsi que le nom<br class='d-none d-lg-block'/> des produits achetés."
                ]
            ],
            [
                "découvrez",
                "immédiatement le lot que vous avez gagné**",
                [
                    "et <b>vous serez automatiquement inscrit au tirage au sort</b> pour tenter de gagner <br class='d-none d-lg-block'/>une <b>prestation Food Truck* à domicile d’une valeur maximale de 1 200€**</b>.<br class='d-none d-lg-block'/><small>**Sous réserve que votre participation soit éligible.</small>"
                ]
            ],
            [
                "SI VOTRE PARTICIPATION EST VALIDE,",
                "",
                [
                    "<b>vous recevrez sous 2 semaines maximum</b> un e-mail avec votre code cadeau Cheerz ou votre bon de réduction La Fournée Dorée ainsi<br class='d-none d-lg-block'/> que les instructions pour en bénéficier. "
                ]
            ]
        ];

        let items = [];

        for (let i = 0; i < 4; i++) {

            items.push(
                <Carousel.Item key={"HowToItem" + i}>

                    <div className={"carousel_howto_item"} id={"carousel_howto_item" + (i + 1)}>
                        <div className={"content"}>
                            <h5 dangerouslySetInnerHTML={{__html : content[i][0]}}></h5>
                            {content[i][1] !== "" && <h5 dangerouslySetInnerHTML={{__html : content[i][1]}}/>}
                            {content[i][2].map((item) => <p dangerouslySetInnerHTML={{__html: item}}/>)}
                        </div>
                    </div>

                </Carousel.Item>
            );
        }

        return (
            <VisibilitySensor onChange={(isVisible) => {setIsCarouselHowToVisible(isVisible);}}>
                <div id={"howto_carousel_wrapper"}>
                    <Carousel controls={true} interval={isCarouselHowToVisible?null:null} indicators={true}>
                        {items}
                    </Carousel>
                </div>
            </VisibilitySensor>
        );
    };
    const createCarouselDotation = () => {
        let items = [];
        let items_desktop = [];

        const content = [
            [
                "Immortalisez vos souvenirs grâce <br class='d-none d-lg-block'/>à 20 tirages photo rétro Cheerz !",
                "<p>Rendez-vous sur le site <br class='d-none d-lg-block'/><a href='https://www.cheerz.com/fr' target='_blank' class='fw-bold'>www.cheerz.com/fr</a> ou l’application,<br class='d-none d-lg-block'/> sélectionnez vos photos et imprimez-les <br class='d-none d-lg-block'/>au format carré pour un effet vintage <br class='d-none d-lg-block'/>indémodable.</p>",
            ],
            [
                "vous vous sentez l’âme d’un chef cuisinier ?",
                "<p>Ça tombe bien, grâce aux bons de réduction <br class='d-none d-lg-block'/>La Fournée Dorée, <b>vous bénéficierez de <br class='d-none d-lg-block'/>0,50€ de réduction sur nos gammes de <br class='d-none d-lg-block'/>pains préemballés lors de votre prochain <br class='d-none d-lg-block'/>passage en caisse.</b></p><p>Burgers ou hot dogs ?<br/>C’est à vous de choisir !</p>"
            ],
            [
                "",
                "<p>Pour votre prochain anniversaire ou <br class='d-none d-lg-block'/>événement avec vos proches, tentez de <br class='d-none d-lg-block'/>gagner <b>une prestation Food Truck<sup>*</sup> à <br class='d-none d-lg-block'/>domicile d’une valeur de 1 200€</b>. De quoi <br class='d-none d-lg-block'/>partager un moment extraordinaire entre <br class='d-none d-lg-block'/>amis ou en famille !</p><span>Le tirage au sort sera effectué le 13/11/2024 parmi les participations valides uniquement.</span>"
            ]
        ];

        for (let i = 0; i < 3; i++) {

            items.push(
                <Carousel.Item key={"DotationItem" + i}>
                    <div className={"carousel_dotation_item"} id={"carousel_dotation_item" + (i + 1)}>
                        <div className={"content"}>
                            {content[i][0] !== "" && <h5 dangerouslySetInnerHTML={{__html: content[i][0]}}/>}
                            {content[i][1] && <div dangerouslySetInnerHTML={{__html : content[i][1]}}/> }
                        </div>
                    </div>
                </Carousel.Item>
            );

            items_desktop.push(
                <div className={"dotation_item"} id={"dotation_item" + (i + 1)}>
                    <div className={"content"}>
                        {content[i][0] !== "" && <h5 dangerouslySetInnerHTML={{__html : content[i][0]}}/> }
                        {content[i][1] && <div dangerouslySetInnerHTML={{__html : content[i][1]}}/> }
                    </div>
                </div>
            )
        }

        return (
            <>
                <VisibilitySensor onChange={(isVisible) => {setIsCarouselDotationToVisible(isVisible);}}>
                    <div id={"dotation_carousel_wrapper"} className={"d-lg-none"}>
                        <Carousel controls={true} interval={isCarouselDotationToVisible?null:null} indicators={true}>
                            {items}
                        </Carousel>
                    </div>
                </VisibilitySensor>

                <div id={"dotation_wrapper"} className={"d-none d-lg-block"}>
                    <div>
                        {items_desktop}
                    </div>
                </div>

            </>
        );
    };

    return (
        <>
            <div id={"homeStrate1"} className={"strate"}/>
            <div id={"homeStrate2"} className={"strate"}>
                <Link to={"/je-participe"} className={"cta"} onClick={() => window.mixpanelhandler.track("I participate_Selected",{"Result count" : 1})}>Je participe</Link>
            </div>
            <div id={"homeStrate3"} className={"strate"}>
                <div className={"anchor"} id={"comment-participer"}/>
                <h2>Comment participer ?</h2>
                {createCarouselHowTo()}
                <div className={"text-center"}>
                    <Link to={"/je-participe"} className={"cta"} onClick={() => window.mixpanelhandler.track("I participate_Selected",{"Result count" : 1})}>Je participe</Link>
                </div>
            </div>
            <div id={"homeStrate4"} className={"strate"}>
                <div className={"anchor"} id={"les-lots"}/>
                <h2>Les lots à gagner</h2>
                {createCarouselDotation()}

            </div>

            <Contact/>
        </>
    );
};


export default Home;
