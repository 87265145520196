import React from 'react';
import Contact from "./contact";
import {Carousel} from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";

const ProduitsEligibles = () => {

    return(
        <div id="produitsEligiblesStrate1" className={"strate last header_global"}>

            <div id={"product_carousel_wrapper"}>
                <Carousel controls={true} interval={5000} indicators={true}>
                    <Carousel.Item key={"ProductsItem1"}>
                        <div className={"carousel_product_item"} id={"carousel_product_item1"}/>
                    </Carousel.Item>
                    <Carousel.Item key={"ProductsItem2"}>
                        <div className={"carousel_product_item"} id={"carousel_product_item2"}/>
                    </Carousel.Item>
                    <Carousel.Item key={"ProductsItem3"}>
                        <div className={"carousel_product_item"} id={"carousel_product_item3"}/>
                    </Carousel.Item>
                    <Carousel.Item key={"ProductsItem4"}>
                        <div className={"carousel_product_item"} id={"carousel_product_item4"}/>
                    </Carousel.Item>
                </Carousel>
            </div>

        </div>
    );
}
export default ProduitsEligibles;
