import React, {useState, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import MediaQuery from "react-responsive/src/Component";

import {Form, Modal, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';

import CustomFieldText from '../form/CustomFieldText';
import CustomComboBox from "../form/CustomComboBox";
import BirthDayField from '../form/BirthDayField';

//import {MixpanelConsumer} from 'react-mixpanel';

import * as Helper from '../form/form_inscription_helper.jsx';
import * as Pattern from '../form/form_pattern_helper.jsx';

import ReCAPTCHA from "react-google-recaptcha";
import Contact from "./contact";
import Routes from "../routes";

const ListeProduit = props => {

    const NomProduit = {
        "": [],
        "Bagels": [
            ["4 Brioch’ Bagels Nature","4 Brioch’ Bagels Nature"],
            ["4 Brioch’ Bagels 2 Sésames","4 Brioch’ Bagels 2 Sésames"],
        ],
        "Baguettes": [
            ["4 Viennois","4 Viennois"],
            ["6 Minis Baguettes Epeautre & Céréales ","6 Petites Baguettes Epeautre & Céréales "],
        ],
        "Bretzels": [
            ["6 Brioch’ Bretzel","6 Brioch’ Bretzel"],
        ],
        "Burgers": [
            ["4 Brioch’ Burgers Nature","4 Brioch’ Burgers Nature"],
            ["4 Brioch’ Burgers Sésame","4 Brioch’ Burgers Sésame"],
            ["4 Brioch’ Burgers Céréales","4 Brioch’ Burgers Céréales"],
            ["4 Brioch’ Burgers Paprika Sésame Noir","4 Brioch’ Burgers Paprika Sésame Noir"],
            ["4 Burgers Gourmets","4 Burgers Gourmets"],
            ["4 Brioch’ Burgers Vegan","4 Brioch’ Burgers Vegan"],
            ["4 Brioch’ Burgers Bio","4 Brioch’ Burgers Bio"],
            ["4 Croissants Burgers","4 Croissants Burgers"],
            ["6 Brioch’ Burgers Nature","6 Brioch’ Burgers Nature"],
            ["6 Brioch’ Burgers Sésame","6 Brioch’ Burgers Sésame"],

        ],
        "Hot Dogs": [
            ["4 MAXI Brioch’ Hot Dogs","4 MAXI Brioch’ Hot Dogs"],
            ["6 Brioch’ Hot Dogs ","6 Brioch’ Hot Dogs "],
        ],
        "Minis Burgers": [
            ["10 Minis Brioch’ Burgers Nature","10 Minis Brioch’ Burgers Nature"],
            ["10 Minis Brioch’ Burgers Sésame","10 Minis Brioch’ Burgers Sésame"],
        ],
        "Minis Sandwichs": [
            ["10 Minis Sandwichs","10 Minis Sandwichs"],
        ],
        "Pains Tranchés": [
            ["La Pain de Mie Gourmand Nature","La Pain de Mie Gourmand Nature"],
            ["Le Pain de Mie Gourmand Complet","Le Pain de Mie Gourmand Complet"],
            ["Le Pain de Mie Gourmand Céréales","Le Pain de Mie Gourmand Céréales"],
        ]
    };

    const label = {
        "1" : "1",
        "2" : "2",
    }

    const [categorieProduit, setCategorieProduit] = useState("");
    const [nomsProduit, setNomsProduit] = useState(NomProduit[categorieProduit]);

    useEffect(() => {
        setNomsProduit(NomProduit[categorieProduit]);
        const combo = document.getElementById(`nom_produit${props.id}`);
        combo.selectedIndex = 0;

    }, [categorieProduit]);

    return (
        <>
            <CustomComboBox name={`categorie_produit${props.id}`} id={`categorie_produit${props.id}`}
                            label={`Catégorie du produit ${label[props.id]}`}
                            placeholder={"Sélectionner"}
                            options={[
                                [
                                    "Bagels",
                                    "Bagels"],
                                [
                                    "Baguettes",
                                    "Baguettes"],
                                [
                                    "Bretzels",
                                    "Bretzels"],
                                [
                                    "Burgers",
                                    "Burgers"],
                                [
                                    "Hot Dogs",
                                    "Hot Dogs"],
                                [
                                    "Minis Burgers",
                                    "Minis Burgers"],
                                [
                                    "Minis Sandwichs",
                                    "Minis Sandwichs"],
                                [
                                    "Pains Tranchés",
                                    "Pains Tranchés"],
                            ]}
                            register={props.register}
                            onChange={(e) => {
                                setCategorieProduit(e.target.value);
                            }}/>

            <CustomComboBox name={`nom_produit${props.id}`} id={`nom_produit${props.id}`}
                            label={`Nom du produit ${label[props.id]}`}
                            placeholder={"Sélectionner"}
                            options={nomsProduit}
                            register={props.register}/>
        </>
    );
};

const JeParticipeForm = (props) => {

    const {register, handleSubmit, errors, clearError, setError, setValue} = useForm({validateCriteriaMode: "all"});
    const recaptchaRef = useRef(null);
    const submitRef = useRef(null);

    //form validation
    const onSubmit = (data) => {

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);
        formData.append("action", "participate");

        fetch(process.env.REACT_APP_API_URL + '/index.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setDotation(json.data.dotation)
                    props.setShowMerci(true);

                    window.mixpanelhandler.track("Participation_Form Submitted",{
                        "Result count" : 1,
                        "Civility / Gender" : data.civilite
                    });

                } else {
                    //setFormError(json.error);
                    alert(json.message);

                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

                window.grecaptcha.reset();

            });

    }

    return (
        <>
            <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

                <div className={"form_part"}>
                    <CustomFieldText name="code_unique1" label="Code unique 1*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.code_unique}
                    })} error={errors.code_unique1 && errors.code_unique1.message}/>
                    <CustomFieldText name="code_unique2" label="Code unique 2*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.code_unique2}
                    })} error={errors.code_unique2 && errors.code_unique2.message}/>

                    <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"Sélectionner"}
                                    options={[
                                        [
                                            "Madame",
                                            "Madame"
                                        ],
                                        [
                                            "Monsieur",
                                            "Monsieur"
                                        ],
                                        [
                                            "Ne souhaite pas se prononcer",
                                            "Ne souhaite pas se prononcer"
                                        ]
                                    ]}
                                    register={register({
                                        required: {
                                            value: true,
                                            message: Helper.EmptyErrorMessages.civilite
                                        }
                                    })}
                                    error={errors.civilite && errors.civilite.message}/>

                    <CustomFieldText name="prenom" label="Prénom*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.prenom},
                        pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom},
                        minLength: {value: 2, message: Helper.InvalidErrorMessages.prenom}
                    })} error={errors.prenom && errors.prenom.message}/>
                    <CustomFieldText name="nom" label="Nom de famille*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.nom},
                        pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom},
                        minLength: {value: 2, message: Helper.InvalidErrorMessages.nom}
                    })} error={errors.nom && errors.nom.message}/>

                    <CustomFieldText name="mail" label="Adresse e-mail*"
                                     register={
                                         register({
                                             required: true,
                                             pattern: Pattern.emailPattern,
                                             validate: {
                                                 domain: (value) => Helper.checkMailDomain(value)
                                                 /*confirm : checkMailConfirm*/
                                             }
                                         })}
                                     error={[
                                         errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                         errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                         errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                         errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                     ]}/>

                    <CustomFieldText name="mail_confirm" label="Confirmation de l'adresse e-mail*"
                                     register={
                                         register({
                                             required: true,
                                             pattern: Pattern.emailPattern,
                                             validate: {
                                                 domain: (value) => Helper.checkMailDomain(value),
                                                 confirm: Helper.checkMailConfirm
                                             }
                                         })}
                                     error={[
                                         errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                         errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                         errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                         errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                                     ]}/>
                    <BirthDayField
                        label={"Date de naissance<span>*</span>"}
                        register={
                            register({
                                required: true,
                                validate: Helper.checkBirthDate
                            })}
                        error={[
                            (errors.jour_naissance && errors.jour_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                            (errors.mois_naissance && errors.mois_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                            (errors.annee_naissance && errors.annee_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance),
                            (errors.jour_naissance && errors.jour_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                            (errors.mois_naissance && errors.mois_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                            (errors.annee_naissance && errors.annee_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age)

                        ]}/>

                </div>

                <div className={"form_part"}>
                    <CustomFieldText name="adresse" label="Adresse postale*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.adresse},
                        pattern: {
                            value: Pattern.numberAndAlphaPattern,
                            message: Helper.InvalidErrorMessages.adresse
                        }
                    })} error={errors.adresse && errors.adresse.message}/>
                    <CustomFieldText name="cp" label="Code postal*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.cp},
                        pattern: {
                            value: Pattern.numberAndAlphaPattern_nospace,
                            message: Helper.InvalidErrorMessages.cp
                        },
                        minLength: {value: 5, message: Helper.InvalidErrorMessages.cp},
                        maxLength: {value: 5, message: Helper.InvalidErrorMessages.cp}
                    })} error={errors.cp && errors.cp.message}/>
                    <CustomFieldText name="ville" label="Ville*" register={register({
                        required: {value: true, message: Helper.EmptyErrorMessages.ville},
                        pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.ville},
                        minLength: {value: 2, message: Helper.InvalidErrorMessages.ville}
                    })} error={errors.ville && errors.ville.message}/>

                    <ListeProduit register={register()} id={1}/>
                    <ListeProduit register={register()} id={2}/>
                </div>

                <div id={"rgpd_text_wrapper"}>

                    <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1"
                               ref={register({required: true})}/>

                        <label htmlFor="optin_reglement">
                            Je reconnais avoir pris connaissance du <a href="/file/Règlement.pdf" target={"_blank"}>Règlement du Jeu</a> de l’opération et en accepter les termes et conditions.<span>*</span>
                        </label>
                        <div className="error text-center">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>
                    </div>

                    <div className={"mandatory"}>
                        *Tous les champs présentant un astérisque sont obligatoires.<br/>
                        Toute demande incomplète ne pourra être validée et traitée.
                    </div>

                    <div id={"optin_partenaire_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_partenaire" id="optin_partenaire" value="1" ref={register()}/>
                        <label htmlFor="optin_partenaire">
                            Oui, je souhaite recevoir les newsletters mensuelles de La Fournée Dorée par e-mail.
                        </label>
                    </div>
                    <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                        <input type="checkbox" name="optin_tlc" id="optin_tlc" value="1" ref={register()}/>
                        <label htmlFor="optin_tlc">
                            Oui, je souhaite être contacté par TLC Worldwide à des fins d'enquêtes de satisfaction, d’information sur nos offres promotionnelle et d'études de marché par e-mail.
                        </label>
                    </div>

                    <p>
                        Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en vigueur ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union Européenne le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, les informations recueillies sont destinées à TLC Marketing en sa qualité de responsable de traitement.
                    </p>
                    <p>
                        TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la confidentialité des données, conformément aux dispositions légales précitées. Vos informations seront conservées jusqu’au
                        31/01/2028 au plus tard conformément à la réglementation si vous avez coché une case opt-in du formulaire de participation. Autrement, vos données seront supprimées à partir du 30/04/2025. Vous bénéficiez d’un droit
                        d’accès, de rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant les données personnelles vous concernant. Afin d’exercer ces droits, nous vous remercions de bien vouloir
                        adresser votre demande auprès de TLC Marketing France par e-mail à <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"}>francedata@tlcrewards.com</a> ou par courrier à l’adresse suivante :
                        TLC Marketing France – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.
                    </p>

                </div>

            </Form>

            {/* recaptcha */}
            <div className="text-center" id={"recaptcha_wrapper"}>
                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b" ref={recaptchaRef}/>
                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
            </div>

            {/* server error */}
            <div className="text-center">
                <div className={"error text-center mb-3"} id={"global_error"}>
                    {/*error*/}
                </div>
                <button className={"cta"} id={"submit_cta"} ref={submitRef} form={"form_participe"}>Je participe</button>
            </div>

        </>
    );
};

const JeParticipeMerci = (props) => {

    return (
        <>
            <div id={"bravoStrate1"} className={"strate header_global"}>

                <h2>VOUS AVEZ GAGNÉ</h2>

                <div className={`dotation ${props.dotation.toLowerCase()}`}/>

                <div className={"mention"}>
                    <div>
                        <p><b>Si votre participation est valide,</b> vous recevrez sous 2 semaines maximum un e-mail vous <br className={"d-none d-lg-block"}/>confirmant votre lot et toutes les instructions pour en bénéficier. <b>Vous serez également <br className={"d-none d-lg-block"}/>automatiquement
                            inscrit au tirage au sort qui aura lieu le 13/11/2024</b> pour tenter de gagner <br className={"d-none d-lg-block"}/>une prestation <b>Food Truck* à domicile</b> !</p>
                        <p><b>Dans le cas contraire, si elle n’est pas valide</b>, vous recevrez un e-mail<br className={"d-none d-lg-block"}/> vous en expliquant la raison.</p>
                        <p><b>Si vous ne recevez pas cet e-mail</b>, n’oubliez pas de regarder dans vos<br className={"d-none d-lg-block"}/> courriers indésirables ou spams.</p>
                    </div>
                    <Link to={"/"}/>
                </div>


            </div>

            <Contact/>
        </>
    );
};

const JeParticipe = () => {

    const [showMerci, setShowMerci] = useState(false);
    const [dotation, setDotation] = useState("");

    return (
        <>
            {!showMerci &&
                <div id={"participeStrate1"} className={"strate header_global last"}>
                    <JeParticipeForm setShowMerci={setShowMerci} setDotation={setDotation} mixpanel={window.mixpanelhandler}/>
                </div>
            }

            {showMerci && <JeParticipeMerci dotation={dotation}/>}

        </>
    );
};

export default JeParticipe;
