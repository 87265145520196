import React from 'react';
import Contact from "./contact";

const Attente = () => {

  return(
      <>
          <div id="strate1" className={"strate last header_global"}>
              <div className={"visuel"}/>
              <p>L’opération "Food Truck" <br className={"d-lg-none"}/>
                  de La Fournée Dorée débutera <br/>
                  <b>le 19 août prochain !</b>
              </p>

              <p>à très bientôt !</p>

          </div>
      </>
  );
}

export default Attente;
