import React from 'react';
import {HashLink as Link} from 'react-router-hash-link';

const Footer = () => {

  return (
    <footer>

      <div id={"footer_wrapper"}>

        <div>
          <p>*French = français / Food Truck = camion-restaurant / Street Food = cuisine de rue.</p>
          <p>Offre nominative, cumulable, limitée à 1 participation par foyer (même nom, même prénom et même adresse postale) et par adresse e-mail, valable pour l’achat<br className={"d-none d-lg-block"}/> de deux produits La Fournée Dorée
            porteurs de l’offre promotionnelle du 19/08/2024 au 20/10/2024 inclus (jusqu’à épuisement des stocks). L’offre donne droit à <br className={"d-none d-lg-block"}/>un
            bon de réduction La Fournée Dorée ou un code cadeau Cheerz pour 20 tirages photo rétro.<br className={"d-none d-lg-block"}/> Voir Règlement du Jeu complet <a href={"/file/Règlement.pdf"} target={"_blank"}>ici</a>. Visuels non
              contractuels.</p>
        </div>

        <div>
        <div className={"footer_logo"}>
            <a id={"footer_logo_partenaire"} href={"https://www.lafourneedoree.fr/"} target={"_blank"}/>
            <a id={"footer_logo_tlc"} href={"https://www.tlcmarketing.com/france"} target={"_blank"}/>
          </div>
          <div className={"footer_menu_wrapper"}>
            <nav>
              <Link to={"/accueil#contact"} smooth>Contact</Link>
              <a href={"/file/Règlement.pdf"} target={"_blank"}>Règlement du Jeu</a>
              <a href={"/file/Politique%20de%20confidentialité.pdf"} target={"_blank"}>Politique de Confidentialité</a>
              <a href={"/file/Politique%20de%20cookies.pdf"} target={"_blank"}>Politique de Cookies</a>
              <Link to={"/mentions-legales"}>Informations Légales</Link>
            </nav>
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
